import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";
import RealExpensesSummarySearchRequest from "../../interfaces/RealExpensesSummarySearchRequest";
import PlannedExpenseRequestDto from "../../dto/PlannedExpenseRequestDto";
import PlannedExpenseUpdateRequestDto from "../../dto/PlannedExpenseUpdateRequestDto";
import MarkPaidRequestDto from "../../dto/MarkPaidRequestDto";
import SuggestionRequestDto from "../../dto/SuggestionRequestDto";
import PlannedExpenseSuggestionDto from "../../dto/PlannedExpenseSuggestionDto";
import PlannedExpenseBalanceDto from "../../dto/PlannedExpenseBalanceDto";
import SearchPlannedExpensesRequestDto from "../../dto/SearchPlannedExpensesRequestDto";
import PlannedExpenseDto from "../../dto/PlannedExpenseDto";
import PageableDto from "../../dto/PageableDto";
import PlannedExpenseDropdownRequest from "../../interfaces/PlannedExpenseDropdownRequest";

const plannedExpenseApi = createApi({
    reducerPath: "plannedExpense",
    tagTypes: ['PlannedExpenses'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/planned-expense`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createPlannedExpense: builder.mutation({
            query: (body: PlannedExpenseRequestDto) => { // todo: clean up interfaces dtos and interfaces folder to one place
                return {
                    url: '',
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: ['PlannedExpenses'],
        }),
        updatePlannedExpense: builder.mutation({
            query: (body: PlannedExpenseUpdateRequestDto) => {
                return {
                    url: `/${body.businessKey}`,
                    method: 'PUT',
                    body: body
                }
            },
            invalidatesTags: ['PlannedExpenses'],
        }),
        deletePlannedExpense: builder.mutation({
            query: (businessKey) => {
                return {
                    url: `/${businessKey}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: ['PlannedExpenses'],
        }),
        fetchPlannedExpenses: builder.query<PageableDto<PlannedExpenseDto[]>, SearchPlannedExpensesRequestDto>({
            query: (request: SearchPlannedExpensesRequestDto) => {
                const {page, pageSize, dateFrom, dateTo, q} = request;
                return {
                    url: `/search?q=${q}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${pageSize}`,
                    method: 'POST',
                    body: request
                }
            },
            providesTags: ['PlannedExpenses'],
        }),
        fetchPlannedExpensesDropdown: builder.query<PlannedExpenseDto[], PlannedExpenseDropdownRequest>({
            query: (request: PlannedExpenseDropdownRequest) => {
                const {budgetBusinessKey, dateFrom, dateTo} = request;
                return {
                    url: `/dropdown/${budgetBusinessKey}?dateFrom=${dateFrom}&dateTo=${dateTo}`,
                    method: 'GET'
                }
            },
            providesTags: ['PlannedExpenses'],
        }),
        fetchPlannedExpenseSummary: builder.query({
            query: (request: RealExpensesSummarySearchRequest) => {
                const {budgetBusinessKey, dateFrom, dateTo} = request;
                return {
                    url: `/summary/${budgetBusinessKey}?dateFrom=${dateFrom}&dateTo=${dateTo}`,
                    method: 'GET'
                }
            },
            providesTags: ['PlannedExpenses'],
        }),
        fetchPlannedExpenseSuggestions: builder.query<PlannedExpenseSuggestionDto[], SuggestionRequestDto>({
            query: (request: SuggestionRequestDto) => {
                const {budgetBusinessKey} = request;
                return {
                    url: `/suggestions/${budgetBusinessKey}`,
                    method: 'POST',
                    body: request
                }
            }
        }),
        fetchPlannedExpenseBalance: builder.query<PlannedExpenseBalanceDto, string>({
            query: (businessKey) => {
                return {
                    url: `/${businessKey}/balance`,
                    method: 'GET'
                }
            },
            providesTags: ['PlannedExpenses'],
        }),
        markPlannedExpensePaid: builder.mutation({
            query: (request: MarkPaidRequestDto) => {
                return {
                    url: `/mark-paid/${request.businessKey}`,
                    method: 'PUT',
                    body: request
                }
            },
            invalidatesTags: ['PlannedExpenses'],
        }),
    })
});

export const {
    useFetchPlannedExpensesQuery,
    useCreatePlannedExpenseMutation,
    useUpdatePlannedExpenseMutation,
    useDeletePlannedExpenseMutation,
    useFetchPlannedExpenseSummaryQuery,
    useMarkPlannedExpensePaidMutation,
    useFetchPlannedExpenseSuggestionsQuery,
    useLazyFetchPlannedExpenseBalanceQuery,
    useFetchPlannedExpensesDropdownQuery
} = plannedExpenseApi;
export {plannedExpenseApi};