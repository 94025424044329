import * as React from 'react';
import {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Alert, CircularProgress, InputAdornment, TableHead, TextField} from "@mui/material";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import RealExpenseEditDialog from "../real-expense-edit-dialog/RealExpenseEditDialog";
import {defaultPage, defaultPageSize, rowsPerPageOptions} from "../../constants/pagination";
import {formatDateTimeString} from "../../utils/date.utils";
import RealExpenseDelete from "../real-expense-delete/RealExpenseDelete";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {getCookieTime} from "../../utils/cookie.utils";
import Box from "@mui/material/Box";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";
import {cookies} from "../../utils/cookies";
import PlannedExpenseDropdownDto from "../../dto/PlannedExpenseDropdownDto";
import {useFetchRealExpensesQuery} from "../../store";
import {Timeout} from "react-number-format/types/types";
import {LoadingItem} from "../loading-item/LoadingItem";

interface Props {
    selectedBudget: string;
    plannedExpenses: PlannedExpenseDropdownDto[];
    expenseCategories: ExpenseCategoryDto[];
    dateFrom: string,
    dateTo: string
}

export default function RealExpenseList(props: Props) {
    const {t} = useTranslation();
    const {
        selectedBudget,
        expenseCategories,
        plannedExpenses,
        dateFrom,
        dateTo
    } = props;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [searchQuery, setSearchQuery] = useState('');
    const [loadTimeoutId, setLoadTimeoutId] = useState<Timeout | undefined>(undefined);
    const {
        data: pageable,
        error,
        isLoading,
    } = useFetchRealExpensesQuery({
        budgetBusinessKey: selectedBudget,
        page: page,
        pageSize: pageSize,
        dateFrom: dateFrom,
        dateTo: dateTo,
        query: searchQuery
    }, {
        skip: !selectedBudget
    });

    const delayLoadRealExpenses = (query: string) => {
        if (typeof loadTimeoutId === 'number') {
            clearTimeout(loadTimeoutId);
            setLoadTimeoutId(undefined);
        }

        const newId = setTimeout(() => {
            setPage(defaultPage);
            setPageSize(defaultPageSize);
            setSearchQuery(query);
        }, 1500);
        setLoadTimeoutId(newId);
    };

    const onChangeQuery = (event: any) => {
        let cleanedValue = event.target.value.replace(/[^\w\s.,]/g, "");
        cleanedValue = cleanedValue.replace(",", ".");
        setSearchQuery(cleanedValue);
        delayLoadRealExpenses(cleanedValue);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    };

    const onClearQuery = () => {
        var simulatedEvent = {
            target: {
                value: ''
            }
        };
        onChangeQuery(simulatedEvent);
    };

    if (isLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (error) {
        return <Alert severity="error">{t("Cannot load expenses!")}</Alert>;
    }

    return (
        <div>
            {pageable?.content
                ? <div>
                    <Box sx={{paddingTop: '1%', paddingBottom: '1%'}}>
                        <TextField fullWidth
                                   id="search-query"
                                   label={t('Search')}
                                   variant="outlined"
                                   value={searchQuery}
                                   onChange={onChangeQuery}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end"><IconButton
                                           aria-label={`${t('Clear search value')}`}
                                           onClick={onClearQuery}
                                           edge="end"
                                           disabled={searchQuery.length === 0}
                                       >
                                           <ClearIcon/>
                                       </IconButton></InputAdornment>,
                                   }}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 200}} aria-label="Real expenses table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Description')}</TableCell>
                                    <TableCell>{t('Amount')}</TableCell>
                                    <TableCell>{t('Category')}</TableCell>
                                    <TableCell>{t('Planned')}</TableCell>
                                    <TableCell>{t('Paid at')}</TableCell>
                                    <TableCell>{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageable.content.map((row) => (
                                    <TableRow key={`real-expense-${row.businessKey}`}>
                                        <TableCell style={{width: 100}} align="left">
                                            {row.description}
                                        </TableCell>
                                        <TableCell style={{width: 10}} align="left">
                                            {row.amount} zł.
                                        </TableCell>
                                        <TableCell style={{width: 20}} align="left">
                                            {row.category.name}
                                        </TableCell>
                                        <TableCell style={{width: 10}} align="left">
                                            {row.plannedExpenseBusinessKey ? <RadioButtonCheckedIcon/> :
                                                <RadioButtonUncheckedIcon/>}
                                        </TableCell>
                                        <TableCell style={{width: 50}} align="left">
                                            {formatDateTimeString(row.paidAt)}
                                        </TableCell>
                                        <TableCell style={{width: 50}} align="left">
                                            <RealExpenseEditDialog realExpense={row}
                                                                   plannedExpenses={plannedExpenses}
                                                                   expenseCategories={expenseCategories}
                                                                   onSave={() => {//todo: check is necessary
                                                                   }}
                                            />
                                            <RealExpenseDelete row={row}
                                                               onDeleted={() => {//todo: check is necessary
                                                               }}/>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        colSpan={3}
                                        count={pageable.totalElements}
                                        rowsPerPage={pageSize}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': `${t('rows per page')}`,
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer></div> : <p>{t('No data')}</p>}
        </div>
    );
}