import * as React from 'react';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "@mui/material";
import {t} from "i18next";
import RevenueForm from "../../components/revenue-form/RevenueForm";
import {useDispatch, useSelector} from "react-redux";
import {RootState, setIsLoading, useCreateRevenueMutation} from "../../store";


interface CreateFirstRevenueProps {
    onSubmit: () => void
}

function CreateFirstRevenue(props: CreateFirstRevenueProps) {
    const {onSubmit} = props;
    const selectedBudget = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const dispatch = useDispatch();
    const [createRevenue, {isLoading, error}] = useCreateRevenueMutation(); // todo: handle isLoading and error
    const handleOnSave = async (data: any) => {
        dispatch(setIsLoading(true)); // todo: check its necessary
        await createRevenue(data);
        onSubmit();
        dispatch(setIsLoading(false));
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('Create first revenue')}</Typography>
            </Breadcrumbs>
            <h1>Twój pierwszy budżet już jest gotowy!</h1>
            <h2>Utwórz teraz swój pierwszy przychód.</h2>
            <p>Na przykład pensja, zasiłek, pieniądze za sprzedane przedmioty.</p>
            <RevenueForm
                budgetBusinessKey={selectedBudget}
                onSave={handleOnSave}
                showSaveButton={true}
                defaultReceiptAt={new Date()}
            />
        </div>
    );
}

export default CreateFirstRevenue;