import * as React from 'react';
import {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ExpenseCategoryRequestDto from "../../dto/ExpenseCategoryRequestDto";
import {useTranslation} from "react-i18next";
import FormItem from "../form-item/FormItem";
import {useCreateExpenseCategoryMutation} from "../../store";
import {LoadingItem} from "../loading-item/LoadingItem";


interface Props {
    budgetBusinessKey: string
}

function ExpenseCategoryForm({budgetBusinessKey}: Props) {
    const {t} = useTranslation();
    const {
        setValue,
        register,
        handleSubmit,
        getValues,
        reset,
        formState: {errors}
    } = useForm();
    const [createExpenseCategory, {isLoading, error}] = useCreateExpenseCategoryMutation();
    const [open, setOpen] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>();


    const handleClickOpen = () => {
        setOpen(true);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 100);
    };

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const handleCreate = () => {
        const data = getValues();
        onSubmit(data);
    };

    const onSubmit = (data: any) => {
        const categoryData = data as ExpenseCategoryRequestDto;
        createExpenseCategory(categoryData);
        reset();
        setOpen(false);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {

        }, 1000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [open]);

    useEffect(() => {
        setValue("budgetBusinessKey", budgetBusinessKey);
    }, [budgetBusinessKey]);


    return (
        <div>
            <Button fullWidth variant="outlined" onClick={handleClickOpen}>
                {t('Create new expense category')}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('Create expense category')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Create new expense category')}
                    </DialogContentText>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{width: '100%'}}>
                            <Stack spacing={2}>
                                <FormItem>
                                    <TextField
                                        fullWidth
                                        inputRef={inputRef}
                                        defaultValue="" {...register("name", {required: true})}
                                        label="Name"
                                        helperText={errors.name && t('Name is required')}
                                    />
                                </FormItem>
                            </Stack>
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    {!isLoading ? <Button onClick={handleCreate}>{t('Create')}</Button>
                        : <LoadingItem><CircularProgress/></LoadingItem>}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ExpenseCategoryForm;