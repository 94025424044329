import * as React from 'react';
import {Breadcrumbs, Grid} from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import ContentItem from "../../components/content-item/ContentItem";
import {useSelector} from "react-redux";
import {RootState, useFetchExpenseCategoriesQuery} from "../../store";
import PlannedExpenseWidget from "../../components/planned-expense-widget/PlannedExpenseWidget";
import {prepareDateFrom, prepareDateTo} from "../../utils/filter.utils";
import {PlannedExpenseWidgetContext} from "../../enums/plannedExpenseWidgetContext";


function Bills() {
    const {t} = useTranslation();
    const selectedBudget = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const {data: expenseCategories} = useFetchExpenseCategoriesQuery(selectedBudget, {
        skip: !selectedBudget
    });

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <ContentItem>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/">
                                Home
                            </Link>
                            <Typography color="text.primary">{t('Bills')}</Typography>
                        </Breadcrumbs>
                        <h1>{t('Bills')}</h1>
                        <PlannedExpenseWidget
                            budgetBusinessKey={selectedBudget}
                            dateFrom={prepareDateFrom()}
                            dateTo={prepareDateTo()}
                            context={PlannedExpenseWidgetContext.BILLS}
                        />
                    </ContentItem>
                </Grid>
            </Grid>
        </div>
    )
}

export default Bills;