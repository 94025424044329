import {createApi, fetchBaseQuery, FetchBaseQueryMeta} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";


const isOnlineApi = createApi({
    reducerPath: "isOnline",
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/connection-check`
    }),
    endpoints: (builder) => ({
        fetchIsOnline: builder.query<boolean, void>({
            query: () => {
                return {
                    url: '',
                    method: 'GET'
                }
            },
            transformResponse: (response, meta: FetchBaseQueryMeta) => {
                if (!meta.response) {
                    return false;
                }

                return meta.response.ok;
            },
        })
    })
});

export const {useFetchIsOnlineQuery} = isOnlineApi;
export {isOnlineApi};