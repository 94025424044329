import * as React from 'react';
import {Alert, CircularProgress, FormControl, InputLabel, Select, SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import BudgetDropdownItemDto from "../../dto/BudgetDropdownItemDto";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RootState, setSelectedBudget, useFetchBudgetsDropdownQuery} from "../../store";
import {LoadingItem} from "../loading-item/LoadingItem";


function BudgetDropdown() {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const selectedBudget = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const {data: budgets, error, isLoading} = useFetchBudgetsDropdownQuery(null);

    const onChange = (event: SelectChangeEvent<any>) => {
        dispatch(setSelectedBudget(event.target.value));
    }

    return (
        <div>
            {isLoading && (<LoadingItem><CircularProgress/></LoadingItem>)}
            {error && (<Alert severity="error">{t("Cannot create budget!")}</Alert>)}
            {budgets.length > 0 ? <div>
                <FormControl fullWidth>
                    <InputLabel id="budget-select-label">{t('Budget')}</InputLabel>
                    <Select
                        labelId="budget-select-label"
                        id="budget-select"
                        label={t('Select budget')}
                        defaultValue=""
                        value={selectedBudget}
                        onChange={onChange}
                    >
                        {budgets.map((item: BudgetDropdownItemDto) =>
                            <MenuItem key={item.businessKey + item.name}
                                      value={item.businessKey}>{item.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div> : <div>{t('No budgets')}</div>}
            {!selectedBudget && budgets.length > 0 ? <p>{t('Select budget')}.</p> : null}

            {budgets.length === 0
                ? <div>{t('Create your first budget')} <Button variant="contained"
                                                               href="/budget">{t('Create budget')}</Button></div>
                : null}
        </div>
    );
}

export default BudgetDropdown;