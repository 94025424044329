import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    RootState,
    setSelectedBudget,
    useFetchBudgetsDropdownQuery,
    useFetchExpenseCategoriesQuery,
    useFetchUserWhoamiQuery
} from "../../store";
import {LoadingItem} from "../loading-item/LoadingItem";
import {CircularProgress} from "@mui/material";

interface AppDataLoaderProps {
    children: React.ReactNode;
}

const AppDataLoader = ({children}: AppDataLoaderProps) => {
    const dispatch = useDispatch();
    const {data: budgetsDropdownData, isFetching: isFetchingBudgetsDropdown} = useFetchBudgetsDropdownQuery(null);
    const {isFetching: isFetchingUserWhoami} = useFetchUserWhoamiQuery(null);
    const selectedBudget = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const {data: expenseCategories, isFetching: isFetchingExpenseCategories} = useFetchExpenseCategoriesQuery(
        selectedBudget, {
            skip: !selectedBudget
        }
    );

    useEffect(() => {
        if (!isFetchingBudgetsDropdown && !isFetchingUserWhoami) {
            if (budgetsDropdownData && budgetsDropdownData.length > 0) {
                const businessKey = budgetsDropdownData[0].businessKey;
                dispatch(setSelectedBudget(businessKey));
            }
        }
    }, [isFetchingBudgetsDropdown, isFetchingUserWhoami, isFetchingExpenseCategories, budgetsDropdownData, dispatch]);

    if (isFetchingBudgetsDropdown || isFetchingUserWhoami || (isFetchingExpenseCategories && !expenseCategories)) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    return <>{children}</>;
};

export default AppDataLoader;