import {useDispatch, useSelector} from "react-redux";
import {clearAuthData, RootState, setAuthData} from "../../store";
import {useEffect, useState} from "react";
import {Timeout} from "react-number-format/types/types";
import {postRefreshToken} from "../../api/api";
import {parseISODateToDiffInMilliseconds} from "../../utils/date.utils";

export default function Auth() {
    const dispatch = useDispatch();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const {
        token, tokenExpiresAt, refreshToken
    } = useSelector((state: RootState) => {
        return state.auth
    });
    const [
        refreshTokenTimeoutId, setRefreshTokenTimeoutId
    ] = useState<Timeout | undefined>(undefined);

    const delayRefreshToken = (expiresAt: string) => {
        if (typeof refreshTokenTimeoutId === 'number') {
            clearTimeout(refreshTokenTimeoutId);
            setRefreshTokenTimeoutId(undefined);
        }

        if (!refreshToken) {
            return;
        }

        const milliseconds = parseISODateToDiffInMilliseconds(expiresAt) - 100;
        const newId = setTimeout(() => {
            handleRefreshToken(refreshToken).then();
        }, milliseconds);
        setRefreshTokenTimeoutId(newId);
    };

    const handleRefreshToken = async (rToken: string) => {
        const data = await postRefreshToken(rToken);
        dispatch(setAuthData(data));
    };

    useEffect(() => {
        if (token && tokenExpiresAt && !isAuthorized) {
            delayRefreshToken(tokenExpiresAt);
            setIsAuthorized(true);
        }

        if (!token && refreshToken) {
            handleRefreshToken(refreshToken).then();
        }

        if (!token && !refreshToken) {
            dispatch(clearAuthData());
        }

    }, [token, refreshToken]);

    return null;
};
