import UserMessagesList from "../../components/user-messages-list/UserMessagesList";
import {useFetchUserMessagesQuery} from "../../store";
import React, {useState} from "react";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import {getCookieTime} from "../../utils/cookie.utils";
import {cookies} from "../../utils/cookies";
import {LoadingItem} from "../../components/loading-item/LoadingItem";
import {Alert, CircularProgress} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function UserMessages() {
    const {t} = useTranslation();
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const {data: pageable, isLoading, error} = useFetchUserMessagesQuery(
        {
            page, pageSize
        }
    );

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    };

    if (isLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (error || !pageable) {
        return <Alert severity="error">{t("Cannot load user messages!")}</Alert>;
    }

    return (
        <div>
            <UserMessagesList
                pageable={pageable}
                page={page}
                pageSize={pageSize}
                onChangePage={handleChangePage}
                onChangePageSize={handleChangeRowsPerPage}
            />
        </div>
    );
}