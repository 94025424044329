import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";
import BudgetSummarySearchRequest from "../../interfaces/BudgetSummarySearchRequest";

const budgetSummaryApi = createApi({
    reducerPath: "budgetSummary",
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/budget-summary`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchBudgetSummary: builder.query({
            query: (request: BudgetSummarySearchRequest) => {
                const {budgetBusinessKey, dateFrom, dateTo} = request;
                return {
                    url: `/${budgetBusinessKey}?dateFrom=${dateFrom}&dateTo=${dateTo}`,
                    method: 'GET'
                }
            },
        }),
    })
});

export const {useFetchBudgetSummaryQuery} = budgetSummaryApi;
export {budgetSummaryApi};