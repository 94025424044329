import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";
import BudgetsSearchRequest from "../../interfaces/BudgetsSearchRequest";
import BudgetDto from "../../dto/BudgetDto";
import BudgetRequestDto from "../../dto/BudgetRequestDto";
import PageableDto from "../../dto/PageableDto";

const budgetApi = createApi({
    reducerPath: "budget",
    tagTypes: ['BudgetsDropdown', 'Budgets'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/budget`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createBudget: builder.mutation<BudgetDto, BudgetRequestDto>({
            query: (body) => {
                return {
                    url: '',
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: ['BudgetsDropdown', 'Budgets'],
        }),
        fetchBudgets: builder.query<PageableDto<BudgetDto[]>, BudgetsSearchRequest>({
            query: (request: BudgetsSearchRequest) => {
                const {page, pageSize} = request;
                return {
                    url: `/my?page=${page}&size=${pageSize}`,
                    method: 'GET'
                }
            },
            providesTags: ['Budgets'],
        }),
    })
});

export const {useCreateBudgetMutation, useFetchBudgetsQuery} = budgetApi;
export {budgetApi};