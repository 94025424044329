import * as React from 'react';
import Paper from '@mui/material/Paper';
import {Alert, AlertTitle, CircularProgress, Stack, styled} from "@mui/material";
import {useTranslation} from "react-i18next";
import PlannedExpenseSuggestionDto from "../../dto/PlannedExpenseSuggestionDto";
import PlannedExpenseRepeatDialog from "../planned-expense-repeat-dialog/PlannedExpenseRepeatDialog";
import {useFetchPlannedExpenseSuggestionsQuery} from "../../store";
import {LoadingItem} from "../loading-item/LoadingItem";


const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

interface Props {
    budgetBusinessKey: string;
    yearMonth: string;
}

export default function PlannedExpenseSuggestions(props: Props) {
    const {t} = useTranslation();
    const {
        budgetBusinessKey,
        yearMonth
    } = props;
    const {data: suggestions, isLoading, error} = useFetchPlannedExpenseSuggestionsQuery(
        {
            budgetBusinessKey,
            yearMonth
        }
    );

    const isToRepeat = (suggestion: PlannedExpenseSuggestionDto) => {
        return suggestion.tags.includes("TO_REPEAT");
    }

    const hideSuggestionByExpenseId = (businessKey: string) => {
        // const updated = suggestions.filter(
        //     element => element.plannedExpense.businessKey !== businessKey
        // );
        // setSuggestions(updated);
        // todo: fix it
    };

    if (error) {
        return <Alert severity="error">{t("Cannot load expense suggestions.")}</Alert>;
    }

    if (isLoading || !suggestions) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }


    return (
        <div>
            {suggestions.length > 0
                ?
                <div>
                    <Stack spacing={2}>
                        <h2>Suggestions</h2>
                        {suggestions.filter(item => isToRepeat(item))
                            .map((suggestion) => (
                                <Item key={suggestion.plannedExpense.businessKey}>
                                    <Alert severity="info" onClose={() => hideSuggestionByExpenseId(
                                        suggestion.plannedExpense.businessKey
                                    )}>
                                        <AlertTitle>
                                            {t('Expense')} {suggestion.plannedExpense.description} {t('repeats')}
                                        </AlertTitle>
                                        <p>
                                            {t('If you need repeat this expense click')}
                                            <PlannedExpenseRepeatDialog
                                                plannedExpense={suggestion.plannedExpense}
                                                onSave={() => hideSuggestionByExpenseId(
                                                    suggestion.plannedExpense.businessKey
                                                )}
                                            />
                                        </p>

                                    </Alert>
                                </Item>
                            ))}
                    </Stack>
                </div> : null}
        </div>
    );
}