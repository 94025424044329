import React, {useEffect} from 'react';
import './App.css';
import AppRouter from "./AppRouter";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {useDispatch} from "react-redux";
import {setIsLoading, useFetchIsOnlineQuery} from "./store";
import AppLoader from "./components/app-loader/AppLoader";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


function App() {
    const {isLoading} = useFetchIsOnlineQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsLoading(isLoading));
    }, [isLoading]);


    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <AppRouter/>
            <AppLoader/>
        </ThemeProvider>
    );
}

export default App;
