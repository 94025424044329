import * as React from "react";
import {useTranslation} from "react-i18next";
import PlanningRequestDto from "../../dto/PlanningRequestDto";
import {addMonths, format} from "date-fns";
import PageableDto from "../../dto/PageableDto";
import PlanningDto from "../../dto/PlanningDto";
import Button from "@mui/material/Button";
import {Alert} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useCreatePlanningMutation} from "../../store";


interface Props {
    budget: string,
    pageable: PageableDto<PlanningDto[]>,
}

export default function PlanningAlerts(props: Props) {
    const {t} = useTranslation();
    const {
        budget, pageable
    } = props;
    const navigate = useNavigate();
    const [createPlanning, {isLoading, error}] = useCreatePlanningMutation();

    const getActualMonthValue = () => {
        const currentDate = new Date();
        return format(currentDate, 'yyyy-MM');
    };

    const getNextMonthValue = () => {
        const currentDate = new Date();
        const nextMonth = addMonths(currentDate, 1);
        return format(nextMonth, 'yyyy-MM');
    };

    const shouldCreateFirstPlanning = () => {
        return pageable.totalElements === 0;
    };
    const shouldCreateActualMonthPlanning = () => {
        const planning = findPlanningByMonth(getActualMonthValue());
        return pageable.totalElements > 0 && !planning;
    };

    const shouldCreateNextMonthPlanning = () => {
        const planning = findPlanningByMonth(getNextMonthValue());
        return pageable.totalElements > 0 && !planning;
    };

    const shouldFinishNextMonthPlanning = () => {
        const planning = findPlanningByMonth(getNextMonthValue());
        if (!planning) {
            return false;
        }
        return pageable.totalElements > 0 && planning.state !== "PLANNED";
    };

    const shouldFinishActualMonthPlanning = () => {
        const planning = findPlanningByMonth(getActualMonthValue());
        if (!planning) {
            return false;
        }
        return pageable.totalElements > 0 && planning.state !== "PLANNED";
    };

    const findPlanningByMonth = (yearMonth: string) => {
        return pageable.content.find(obj => obj.yearMonth === yearMonth);
    };

    const continuePlanning = (yearMonth: string) => {
        const planning = findPlanningByMonth(yearMonth);
        navigate(`/planning/${planning?.id}`);
    };

    const startPlanning = async (yearMonth: string) => {
        const request = {
            "yearMonth": yearMonth,
            "budgetBusinessKey": budget
        } as PlanningRequestDto;
        const planning = await createPlanning(request).unwrap();
        navigate(`/planning/${planning.id}`);
    };

    return (
        <div>
            {shouldCreateFirstPlanning()
                ? <div>
                    <Alert
                        severity="warning"
                        action={
                            <Button variant="outlined" color="inherit" size="small"
                                    onClick={() => startPlanning(getActualMonthValue())}>{t('Start first planning')}
                            </Button>
                        }
                    >{t("You don't have any monthly plans yet.")}</Alert>
                </div>
                : null}
            {shouldCreateActualMonthPlanning()
                ? <Alert
                    severity="warning"
                    action={
                        <Button variant="outlined" color="inherit" size="small"
                                onClick={() => startPlanning(getActualMonthValue())}>{t('Start planning')}
                        </Button>
                    }
                >
                    {t("The current month is not planned.")}
                </Alert>
                : null}
            {shouldFinishActualMonthPlanning()
                ? <Alert
                    severity="warning"
                    action={
                        <Button variant="outlined" color="inherit" size="small"
                                onClick={() => continuePlanning(getActualMonthValue())}>{t('Continue planning')}
                        </Button>
                    }
                >
                    {t("The planning for the current month has not been finished.")}
                </Alert>
                : null}
            {shouldCreateNextMonthPlanning()
                ? <Alert
                    severity="info"
                    action={
                        <Button variant="outlined" color="inherit" size="small"
                                onClick={() => startPlanning(getNextMonthValue())}>{t('Start planning')}
                        </Button>
                    }
                >
                    {t("The next month is not planned.")}
                </Alert>
                : null}
            {shouldFinishNextMonthPlanning()
                ? <Alert
                    severity="info"
                    action={
                        <Button variant="outlined" color="inherit" size="small"
                                onClick={() => continuePlanning(getNextMonthValue())}>{t('Continue planning')}
                        </Button>
                    }
                >
                    {t("The planning for the next month has not been finished.")}
                </Alert>
                : null}
        </div>
    );
}