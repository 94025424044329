import * as React from 'react';
import {useForm} from "react-hook-form";
import {Alert, CircularProgress, Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import BudgetRequestDto from "../../dto/BudgetRequestDto";
import {useTranslation} from "react-i18next";
import ContentItem from "../content-item/ContentItem";
import {setIsLoading, setSelectedBudget, useCreateBudgetMutation} from "../../store";
import {LoadingItem} from "../loading-item/LoadingItem";
import {useDispatch} from "react-redux";

interface BudgetFormProps {
    onSubmit: () => void;
}

function BudgetForm(props: BudgetFormProps) {
    const {onSubmit: onSubmitBudgetForm} = props;
    const {t} = useTranslation();
    const [createBudget, {error, isLoading}] = useCreateBudgetMutation();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (data: any) => {
        dispatch(setIsLoading(true));
        const budgetData = data as BudgetRequestDto;
        const result = await createBudget(budgetData).unwrap();
        dispatch(setSelectedBudget(result.businessKey));
        onSubmitBudgetForm();
        dispatch(setIsLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Stack spacing={2}>
                    <ContentItem>
                        <TextField
                            fullWidth
                            defaultValue="" {...register("name", {required: true})}
                            label={t('Name')}
                            helperText={errors.name && t('Name is required')}
                            multiline
                        />
                    </ContentItem>
                    <ContentItem>
                        {isLoading && (<LoadingItem><CircularProgress/></LoadingItem>)}
                        {error && (<Alert severity="error">{t("Cannot create budget!")}</Alert>)}
                        {!isLoading && (<Button variant="contained" type="submit">{t('Create')}</Button>)}
                    </ContentItem>
                </Stack>
            </Box>
        </form>
    );
};

export default BudgetForm;