import TokenDto from "../dto/TokenDto";
import {parseISODateToDiffInSeconds} from "./date.utils";
import {cookies} from "./cookies";

export const setAuthCookies = (tokenDto: TokenDto) => {
    const expiresToken = parseISODateToDiffInSeconds(tokenDto.tokenExpiresAt);
    const expiresRefreshToken = parseISODateToDiffInSeconds(tokenDto.refreshTokenExpiresAt);
    cookies.set('token', tokenDto.token, {maxAge: expiresToken});
    cookies.set('tokenExpiresAt', tokenDto.tokenExpiresAt, {maxAge: expiresToken});
    cookies.set('userBusinessKey', tokenDto.businessKey, {maxAge: expiresToken});
    cookies.set('refreshToken', tokenDto.refreshToken, {maxAge: expiresRefreshToken});
};