import * as React from 'react';
import {Alert, Breadcrumbs, CircularProgress, Grid} from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import PlanningMonthStepper from "../../components/planning-month-stepper/PlanningMonthStepper";
import {LoadingItem} from "../../components/loading-item/LoadingItem";
import ContentItem from "../../components/content-item/ContentItem";
import {useSelector} from "react-redux";
import {RootState, useFetchExpenseCategoriesQuery, useFetchPlanningQuery} from "../../store";


function PlanningMonth() {
    const {t} = useTranslation();
    const {id} = useParams();
    const selectedBudget = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const {data: expenseCategories} = useFetchExpenseCategoriesQuery(selectedBudget, {
        skip: !selectedBudget
    });
    const {data: planning, error, isLoading} = useFetchPlanningQuery(id, {
        skip: !id
    });

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    {error && (<Alert severity="error">{t("Cannot load planning!")}</Alert>)}
                    {!isLoading && planning && !error ? <ContentItem>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/">
                                Home
                            </Link>
                            <Link underline="hover" color="inherit" href="/planning">
                                Plannings list
                            </Link>
                            <Typography color="text.primary">{t('Monthly planning')} ({planning.yearMonth})</Typography>
                        </Breadcrumbs>
                        <h1>{t('Monthly planning')} ({planning.yearMonth})</h1>
                        <PlanningMonthStepper
                            planning={planning}
                            expenseCategories={expenseCategories}
                        />
                    </ContentItem> : <LoadingItem><CircularProgress/></LoadingItem>}
                </Grid>
            </Grid>
        </div>
    )
}

export default PlanningMonth;