import * as React from 'react';
import {useState} from 'react';
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import {useTranslation} from "react-i18next";
import RevenueRequestDto from "../../dto/RevenueRequestDto";
import RevenueList from "../revenue-list/RevenueList";
import RevenueCreateDialog from "../revenue-create-dialog/RevenueCreateDialog";
import {Alert, CircularProgress} from "@mui/material";
import {LoadingItem} from "../loading-item/LoadingItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {getCookieTime} from "../../utils/cookie.utils";
import {cookies} from "../../utils/cookies";
import {useCreateRevenueMutation, useFetchRevenuesQuery} from "../../store";

interface Props {
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
    afterSave: (budgetBusinessKey: string, dateFrom: string, dateTo: string) => void,
    onClickContinue: () => void,
    revenueSummaryValid: boolean
}

export default function RevenuePlanning(props: Props) {
    const {t} = useTranslation();
    const {
        budgetBusinessKey,
        dateFrom,
        dateTo,
        afterSave,
        onClickContinue,
        revenueSummaryValid
    } = props;

    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const {data: pageable, error: revenuesError, isLoading: revenuesLoading} = useFetchRevenuesQuery({
        budgetBusinessKey,
        page,
        pageSize,
        dateFrom,
        dateTo
    });
    const [createRevenue, {
        isLoading: isCreateRevenueLoading,
        error: createRevenueError
    }] = useCreateRevenueMutation();

    const onSaveRevenue = async (data: RevenueRequestDto) => {
        await createRevenue(data);
        setPage(defaultPage);
        setPageSize(defaultPageSize);
        afterSave(budgetBusinessKey, dateFrom, dateTo);
    };

    const onChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const onChangePageSize = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    };

    if (isCreateRevenueLoading || revenuesLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (revenuesError) {
        return <Alert severity="error">{t("Cannot load revenues!")}</Alert>
    }

    if (createRevenueError) {
        return <Alert severity="error">{t("Cannot create revenue!")}</Alert>
    }

    return (

        <div>
            <div>
                <RevenueCreateDialog
                    budgetBusinessKey={budgetBusinessKey}
                    onSave={onSaveRevenue}
                    dateFrom={dateFrom}
                />
            </div>
            <div>{!revenueSummaryValid ? <Alert severity="info">
                {t('Add revenues to continue.')}
            </Alert> : null}</div>
            <div>
                <Box sx={{mb: 2}}>
                    <div>
                        <Button
                            variant="contained"
                            disabled={!revenueSummaryValid}
                            onClick={onClickContinue}
                            sx={{mt: 1, mr: 1}}
                        >
                            {t('Complete')}
                        </Button>
                    </div>
                </Box>
            </div>
            <div>
                <h2>{t('Revenues list')}</h2>
                <RevenueList
                    pageable={pageable}
                    page={page}
                    onChangePage={onChangePage}
                    pageSize={pageSize}
                    onChangePageSize={onChangePageSize}
                    afterChange={() => afterSave(budgetBusinessKey, dateFrom, dateTo)}
                />
            </div>
        </div>
    );
}