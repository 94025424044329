import * as React from 'react';
import BudgetForm from "../../components/budget-form/BudgetForm";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "@mui/material";
import {t} from "i18next";

interface CreateFirstBudgetProps {
    onSubmit: () => void;
}

function CreateFirstBudget(props: CreateFirstBudgetProps) {
    const {onSubmit} = props;
    return (
        <>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="text.primary">{t('Create first budget')}</Typography>
                </Breadcrumbs>
                <h1>Witaj w MojeFinanse!</h1>
                <p>Utwórz swój pierwszy budżet, aby móc nim zarządzać.</p>
                <BudgetForm onSubmit={onSubmit}/>
            </div>
        </>

    );
}

export default CreateFirstBudget;