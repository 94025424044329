import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";
import PlanningsSearchRequest from "../../interfaces/PlanningsSearchRequest";
import PlanningUpdateRequestDto from "../../dto/PlanningUpdateRequestDto";

const planningApi = createApi({
    reducerPath: "planning",
    tagTypes: ['Planning'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/planning`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createPlanning: builder.mutation({
            query: (body) => {
                return {
                    url: '',
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: ['Planning'],
        }),
        updatePlanning: builder.mutation({
            query: (body: PlanningUpdateRequestDto) => {
                const {id} = body;
                return {
                    url: `/${id}`,
                    method: 'PUT',
                    body: body
                }
            },
            invalidatesTags: ['Planning'],
        }),
        fetchPlanning: builder.query({
            query: (id) => {
                return {
                    url: `/by-id/${id}`,
                    method: 'GET'
                }
            },
            providesTags: ['Planning'],
        }),
        fetchPlannings: builder.query({
            query: (request: PlanningsSearchRequest) => {
                const {budgetBusinessKey, page, pageSize} = request;
                return {
                    url: `/${budgetBusinessKey}?page=${page}&size=${pageSize}`,
                    method: 'GET'
                }
            },
            providesTags: ['Planning'],
        }),
    })
});

export const {
    useCreatePlanningMutation,
    useUpdatePlanningMutation,
    useFetchPlanningQuery,
    useFetchPlanningsQuery
} = planningApi;
export {planningApi};