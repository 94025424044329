import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    Alert,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {parseISO} from "date-fns";
import RevenueDto from "../../dto/RevenueDto";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import RevenueUpdateRequestDto from "../../dto/RevenueUpdateRequestDto";
import {useTranslation} from "react-i18next";
import {formatDateTime} from "../../utils/date.utils";
import FormItem from "../form-item/FormItem";
import {useUpdateRevenueMutation} from "../../store";
import {LoadingItem} from "../loading-item/LoadingItem";


interface Props {
    revenue: RevenueDto
}

export default function RevenueEditDialog(props: Props) {
    const {t} = useTranslation();
    const {revenue} = props;
    const {register, getValues, setValue, formState: {errors}} = useForm();
    const [open, setOpen] = useState(false);
    const [updateRevenue, {isLoading, error, isSuccess}] = useUpdateRevenueMutation();

    const parseDate = (date: string) => {
        return parseISO(date);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (data: any) => {
        const request = data as RevenueUpdateRequestDto;
        request.businessKey = revenue.businessKey;
        request.budgetBusinessKey = revenue.budgetBusinessKey;
        await updateRevenue(request);
    };

    const handleDateFieldChange = (fieldName: string, date: any) => {
        setValue(fieldName, formatDateTime(date), {shouldValidate: true});
    };

    const onChangeAmount = (event: any) => {
        setValue("amount", event.target.value.replace(",", "."));
    }

    const initForm = () => {
        handleDateFieldChange("receiptAt", parseDate(revenue.receiptAt));
        setValue("name", revenue.name);
        setValue("amount", revenue.amount);
    };

    useEffect(() => {
        initForm();
    }, []);

    useEffect(() => {
        if (isSuccess) {
            handleClose();
        }
    }, [isSuccess]);

    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <EditIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('Edit revenue')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Edit revenue')}: {revenue.name}
                    </DialogContentText>
                    <form>
                        <Box sx={{width: '100%'}}>
                            <Stack spacing={2}>
                                <FormItem>
                                    <TextField
                                        fullWidth
                                        defaultValue={revenue.name}
                                        {...register("name", {required: true})}
                                        label={t('Name')}
                                        helperText={errors.name && t('Name is required')}
                                        multiline
                                    />
                                </FormItem>
                                <FormItem>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="adornment-amount">
                                            {t('Amount')}
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            type="string"
                                            inputProps={{
                                                inputMode: "decimal",
                                                pattern: "[0-9.,]*",
                                                autoComplete: "off"
                                            }}
                                            defaultValue={revenue.amount}
                                            {...register("amount", {required: true})}
                                            onChange={onChangeAmount}
                                            id="adornment-amount"
                                            endAdornment={<InputAdornment position="end">zł.</InputAdornment>}
                                        />
                                        {errors.amount && <FormHelperText>{t('Amount is required')}</FormHelperText>}
                                    </FormControl>
                                </FormItem>
                                <FormItem>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label={t('Receipt at')}
                                            defaultValue={parseDate(revenue.receiptAt)}
                                            onChange={(newValue) => handleDateFieldChange('receiptAt', newValue)}
                                        />
                                    </LocalizationProvider>
                                </FormItem>
                                {isLoading && (<FormItem><LoadingItem><CircularProgress/></LoadingItem></FormItem>)}
                                {error && (
                                    <FormItem><Alert severity="error">{t("Cannot update revenue!")}</Alert></FormItem>)}
                            </Stack>
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button
                        type="submit"
                        onClick={() => onSubmit(getValues())}
                        disabled={isLoading}
                    >{t('Save')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};