import {createSlice} from "@reduxjs/toolkit";
import {cookies} from "../../utils/cookies";

interface SelectedBudgetState {
    selectedBudget: string;
}

const initialState: SelectedBudgetState = {
    selectedBudget: cookies.get("selectedBudget") || null,
};

const selectedBudgetSlice = createSlice({
    name: "selectedBudget",
    initialState: initialState,
    reducers: {
        setSelectedBudget: (state, action) => {
            cookies.set("selectedBudget", action.payload);
            state.selectedBudget = action.payload;
        }
    }
});

export const {setSelectedBudget} = selectedBudgetSlice.actions;
export const selectedBudgetReducer = selectedBudgetSlice.reducer;