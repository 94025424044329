import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PageableDto from "../../dto/PageableDto";
import {InputAdornment, TableHead, TextField} from "@mui/material";
import PlannedExpenseDto from "../../dto/PlannedExpenseDto";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {rowsPerPageOptions} from "../../constants/pagination";
import ClearIcon from "@mui/icons-material/Clear";
import {useTranslation} from "react-i18next";
import PlannedExpenseListRow from "../planned-expense-list-row/planned-expense-list-row";
import {PlannedExpenseWidgetContext} from "../../enums/plannedExpenseWidgetContext";

interface Props {
    pageable: PageableDto<PlannedExpenseDto[]>;
    query: string;
    page: number;
    pageSize: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onChangePageSize: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    loadData: () => void;
    onChangeQuery: any;
    context: PlannedExpenseWidgetContext;
}

export default function PlannedExpenseList(props: Props) {
    const {t} = useTranslation();
    const {
        pageable,
        query,
        page,
        pageSize,
        onChangePage,
        onChangePageSize,
        loadData,
        onChangeQuery,
        context
    } = props;


    const onClearQuery = () => {
        var simulatedEvent = {
            target: {
                value: ''
            }
        };
        onChangeQuery(simulatedEvent);
    };

    return (
        <div>
            {pageable?.content
                ?
                <div>
                    <Box sx={{paddingTop: '1%', paddingBottom: '1%'}}>
                        <TextField fullWidth
                                   id="search-query"
                                   label={t('Search')}
                                   variant="outlined"
                                   value={query}
                                   onChange={onChangeQuery}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end"><IconButton
                                           aria-label={`${t('Clear search value')}`}
                                           onClick={onClearQuery}
                                           edge="end"
                                           disabled={query.length === 0}
                                       >
                                           <ClearIcon/>
                                       </IconButton></InputAdornment>,
                                   }}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 300}} aria-label={`${t('Planned expenses')}`}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t('Description')}</TableCell>
                                    <TableCell>{t('Category')}</TableCell>
                                    <TableCell>{t('Amount')} / {t('Balance')}</TableCell>
                                    <TableCell>{t('To pay at')}</TableCell>
                                    <TableCell>{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageable.content.map((row) => (
                                    <PlannedExpenseListRow
                                        key={`${row.businessKey}-${row.createdAt}`}
                                        row={row}
                                        onChange={loadData}
                                        context={context}
                                    />
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        colSpan={3}
                                        count={pageable.totalElements}
                                        rowsPerPage={pageSize}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': `${t('rows per page')}`,
                                            },
                                            native: true,
                                        }}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangePageSize}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div> : <p>{t('No data')}</p>}
        </div>
    );
}