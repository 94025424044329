import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {TableHead} from "@mui/material";
import {rowsPerPageOptions} from "../../constants/pagination";
import {useTranslation} from "react-i18next";
import BudgetDto from "../../dto/BudgetDto";
import PageableDto from "../../dto/PageableDto";

interface BudgetListProps {
    pageable: PageableDto<BudgetDto[]>,
    page: number,
    pageSize: number,
    onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void,
    onChangePageSize: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}

export default function BudgetList(props: BudgetListProps) {
    const {pageable, page, pageSize, onChangePage, onChangePageSize} = props;
    const {t} = useTranslation();

    return (
        <div>
            {pageable?.content && (<TableContainer component={Paper}>
                <Table sx={{minWidth: 300}} aria-label={`${t('Budget list')}`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Name')}</TableCell>
                            {/*<TableCell>Actions</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageable.content.map((row) => (
                            <TableRow key={`${row.businessKey}-${row.userBusinessKey}`}>
                                <TableCell style={{width: 100}} align="left">
                                    {row.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                colSpan={3}
                                count={pageable.totalElements}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': `${t('rows per page')}`,
                                    },
                                    native: true,
                                }}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangePageSize}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>)}
            {!pageable?.content && (<p>{t('No data')}</p>)}
        </div>
    );
}