import {TableHead} from "@mui/material";
import React from "react";
import PageableDto from "../../dto/PageableDto";
import UserMessageDto from "../../dto/UserMessageDto";
import {useTranslation} from "react-i18next";
import {rowsPerPageOptions} from "../../constants/pagination";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import UserMessagesListItem from "../user-messages-list-item/UserMessagesListItem";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import {useMarkMessageAsReadMutation} from "../../store";

interface Props {
    pageable: PageableDto<UserMessageDto[]>;
    page: number;
    pageSize: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onChangePageSize: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export default function UserMessagesList(props: Props) {
    const {t} = useTranslation();
    const {pageable, page, pageSize, onChangePage, onChangePageSize} = props;
    const [markMessageAsRead, {}] = useMarkMessageAsReadMutation(); //todo: handle isLoading and error

    const handleRead = async (id: string) => {
        await markMessageAsRead(id);
    }

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pageable.content.map(item => (
                                    <UserMessagesListItem
                                        key={`user-message-${item.id}`}
                                        item={item}
                                        onRead={handleRead}
                                    />
                                )
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                colSpan={3}
                                count={pageable.totalElements}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': `${t('rows per page')}`,
                                    },
                                    native: true,
                                }}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangePageSize}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>

            </TableContainer>

        </div>
    );
}