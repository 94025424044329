import React from "react";
import {useSelector} from "react-redux";
import {RootState, useFetchIsOnlineQuery, useFetchUserProgressQuery} from "../../store";
import CreateFirstBudget from "../../pages/create-first-budget/CreateFirstBudget";
import CreateFirstRevenue from "../../pages/create-first-revenue/CreateFirstRevenue";
import Login from "../../pages/login/Login";
import {Alert, CircularProgress} from "@mui/material";
import {LoadingItem} from "../loading-item/LoadingItem";
import {useTranslation} from "react-i18next";

interface RedirectorProps {
    children: React.ReactNode;
}

const Redirector = ({children}: RedirectorProps) => {
    const {t} = useTranslation();
    const isLoading = useSelector((state: RootState) => state.loader.isLoading);
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
    const {data: isOnline} = useFetchIsOnlineQuery();
    const {
        data: userProgress,
        error,
        isFetching: isUserProgressFetching,
        refetch
    } = useFetchUserProgressQuery(undefined, {
        skip: !isLoggedIn || !isOnline
    });

    const onSubmit = () => {
        refetch();
    }

    const isComponentLoading = () => {
        return (userProgress === undefined && !error) || isLoading || isUserProgressFetching;
    }

    if (!isOnline && !isLoading) {
        return <Alert severity="error">{t("Service is OFFLINE! We should come back after a few minutes.")}</Alert>;
    }

    if (isOnline && !isLoggedIn) {
        return <Login/>;
    }

    if (isComponentLoading()) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (error || userProgress.experience == 0) {
        return <CreateFirstBudget onSubmit={onSubmit}/>;
    }

    if (userProgress.experience == 1) {
        return <CreateFirstRevenue onSubmit={onSubmit}/>;
    }


    return <>{children}</>;
};

export default Redirector;