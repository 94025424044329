import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setAuthCookies} from "../../utils/setAuthCookies";
import {clearAuthCookies} from "../../utils/clearAuthCookies";
import {cookies} from "../../utils/cookies";

interface AuthState {
    token: string | null;
    tokenExpiresAt: string | null;
    businessKey: string | null;
    refreshToken: string | null;
    isLoggedIn: boolean;
}

const token = cookies.get('token') || null;
const tokenExpiresAt = cookies.get('tokenExpiresAt') || null;
const businessKey = cookies.get('userBusinessKey') || null;
const refreshToken = cookies.get('refreshToken') || null;

const initialState: AuthState = {
    token: token,
    tokenExpiresAt: tokenExpiresAt,
    businessKey: businessKey,
    refreshToken: refreshToken,
    isLoggedIn: token !== null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData: (
            state,
            action: PayloadAction<{
                token: string;
                businessKey: string;
                refreshToken: string;
                tokenExpiresAt: string;
            }>
        ) => {
            const {
                token, tokenExpiresAt, businessKey, refreshToken,
            } = action.payload;
            setAuthCookies(action.payload);
            state.token = token;
            state.tokenExpiresAt = tokenExpiresAt;
            state.businessKey = businessKey;
            state.refreshToken = refreshToken;
            state.isLoggedIn = token !== null;
        },
        clearAuthData: (state) => {
            state.token = null;
            state.tokenExpiresAt = null;
            state.businessKey = null;
            state.refreshToken = null;
            state.isLoggedIn = false;
            clearAuthCookies();
        },
    },
});

export const {setAuthData, clearAuthData} = authSlice.actions;
export const authReducer = authSlice.reducer;