import {configureStore} from "@reduxjs/toolkit";
import {loaderReducer, setIsLoading} from "./slices/loaderSlice";
import {filterReducer, updateFilter} from "./slices/filterSlice";
import {budgetApi} from "./apis/budgetApi";
import {budgetsDropdownApi} from "./apis/budgetsDropdownApi";
import {setupListeners} from "@reduxjs/toolkit/query";
import {authReducer, clearAuthData, setAuthData} from "./slices/authSlice";
import {selectedBudgetReducer, setSelectedBudget} from "./slices/selectedBudgetSlice";
import {userApi} from "./apis/userApi";
import {expenseCategoryApi} from "./apis/expenseCategoryApi";
import {isOnlineApi} from "./apis/isOnlineApi";
import {expenseDescriptionApi} from "./apis/expenseDescriptionApi";
import {revenueApi} from "./apis/revenueApi";
import {budgetSummaryApi} from "./apis/budgetSummaryApi";
import {realExpenseApi} from "./apis/realExpenseApi";
import {planningApi} from "./apis/planningApi";
import {plannedExpenseApi} from "./apis/plannedExpenseApi";


const store = configureStore({
    reducer: {
        auth: authReducer,
        loader: loaderReducer,
        filter: filterReducer,
        selectedBudget: selectedBudgetReducer,
        [isOnlineApi.reducerPath]: isOnlineApi.reducer,
        [budgetApi.reducerPath]: budgetApi.reducer,
        [budgetsDropdownApi.reducerPath]: budgetsDropdownApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [expenseCategoryApi.reducerPath]: expenseCategoryApi.reducer,
        [expenseDescriptionApi.reducerPath]: expenseDescriptionApi.reducer,
        [revenueApi.reducerPath]: revenueApi.reducer,
        [budgetSummaryApi.reducerPath]: budgetSummaryApi.reducer,
        [realExpenseApi.reducerPath]: realExpenseApi.reducer,
        [plannedExpenseApi.reducerPath]: plannedExpenseApi.reducer,
        [planningApi.reducerPath]: planningApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(
                isOnlineApi.middleware,
                budgetApi.middleware,
                budgetsDropdownApi.middleware,
                userApi.middleware,
                expenseCategoryApi.middleware,
                expenseDescriptionApi.middleware,
                revenueApi.middleware,
                budgetSummaryApi.middleware,
                realExpenseApi.middleware,
                plannedExpenseApi.middleware,
                planningApi.middleware,
            );
    },
});

setupListeners(store.dispatch);

export {
    store,
    setAuthData,
    clearAuthData,
    setIsLoading,
    updateFilter,
    setSelectedBudget
};

export {
    useFetchIsOnlineQuery
} from './apis/isOnlineApi';

export {
    useFetchBudgetsDropdownQuery,
    useLazyFetchBudgetsDropdownQuery
} from './apis/budgetsDropdownApi';

export {
    useCreateBudgetMutation,
    useFetchBudgetsQuery
} from './apis/budgetApi';

export {
    useFetchUserWhoamiQuery,
    useFetchUserProgressQuery,
    useLazyFetchUserProgressQuery,
    useUpdatePasswordMutation,
    useMarkMessageAsReadMutation,
    useFetchUserMessagesQuery,
    useFetchUserMessagesCountUnreadQuery
} from './apis/userApi';

export {
    useFetchExpenseCategoriesQuery,
    useLazyFetchExpenseCategoriesQuery,
    useCreateExpenseCategoryMutation
} from './apis/expenseCategoryApi';

export {
    useFetchExpenseDescriptionsQuery
} from './apis/expenseDescriptionApi';

export {
    useCreateRevenueMutation,
    useFetchRevenuesQuery,
    useDeleteRevenueMutation,
    useUpdateRevenueMutation,
    useFetchRevenueSummaryQuery
} from './apis/revenueApi';

export {
    useFetchBudgetSummaryQuery,
} from './apis/budgetSummaryApi';

export {
    useFetchRealExpensesQuery,
    useDeleteRealExpenseMutation,
    useCreateRealExpenseMutation,
    useUpdateRealExpenseMutation,
    useFetchRealExpenseSummaryQuery,
    useLazyFetchRealExpensesByPlannedQuery
} from './apis/realExpenseApi';

export {
    useFetchPlannedExpensesQuery,
    useDeletePlannedExpenseMutation,
    useCreatePlannedExpenseMutation,
    useUpdatePlannedExpenseMutation,
    useFetchPlannedExpenseSummaryQuery,
    useMarkPlannedExpensePaidMutation,
    useFetchPlannedExpenseSuggestionsQuery,
    useLazyFetchPlannedExpenseBalanceQuery,
    useFetchPlannedExpensesDropdownQuery
} from './apis/plannedExpenseApi';

export {
    useFetchPlanningQuery,
    useCreatePlanningMutation,
    useUpdatePlanningMutation,
    useFetchPlanningsQuery
} from './apis/planningApi';

export type RootState = ReturnType<typeof store.getState>;