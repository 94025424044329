import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {Alert, CircularProgress, LinearProgress, LinearProgressProps, styled, tableCellClasses} from "@mui/material";
import Paper from "@mui/material/Paper";
import BudgetSummaryDto from "../../dto/BudgetSummaryDto";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {useTranslation} from "react-i18next";
import {RootState, useFetchBudgetSummaryQuery} from "../../store";
import {useSelector} from "react-redux";
import {LoadingItem} from "../loading-item/LoadingItem";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface RowItem {
    name: string;
    value: string;
}

export default function BudgetSummary() {
    const {t} = useTranslation();
    const [budgetProgress, setBudgetProgress] = useState(0);
    const [expensesProgress, setExpensesProgress] = useState(0);
    const [tableData, setTableData] = useState<RowItem[]>([]);
    const {dateFrom, dateTo} = useSelector((state: RootState) => state.filter);
    const budgetBusinessKey = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const {data: summary, error, isLoading} = useFetchBudgetSummaryQuery(
        {budgetBusinessKey, dateFrom, dateTo},
        {
            skip: !budgetBusinessKey
        }
    );

    const calculateBudgetProgress = (incomeAmount: string, realExpensesAmount: string) => {
        let progress = 0;
        if (incomeAmount !== '0.00' && realExpensesAmount != '0.00') {
            progress = (parseInt(realExpensesAmount) / parseInt(incomeAmount)) * 100;
            progress.toFixed(2)
        }
        setBudgetProgress(progress);
    };

    const calculateExpensesProgress = (summary: BudgetSummaryDto) => {
        let progress = 0;
        if (summary.realExpensesAmount !== '0.00'
            && summary.unplannedExpensesAmount != '0.00'
        ) {
            progress = (parseInt(summary.unplannedExpensesAmount) / parseInt(summary.realExpensesAmount)) * 100;
            progress.toFixed(2)
        }
        setExpensesProgress(progress);
    };

    const minusOrNot = (value: string) => {
        return value != "0.00" ? "-" : "";
    };

    const prepareTableData = () => {
        const rows = [
            prepareTableRow(t('Budget'), `${summary.incomeAmount} zł.`),
            prepareTableRow(t('Total expenses value'), `${minusOrNot(summary.realExpensesAmount)}${summary.realExpensesAmount} zł.`),
            prepareTableRow(t('Unplanned expenses value'), `${minusOrNot(summary.unplannedExpensesAmount)}${summary.unplannedExpensesAmount} zł.`),
            prepareTableRow(t('Planned expenses'), `${summary.plannedExpensesAmount} zł.`),
            prepareTableRow(t('Planned balance'), `${summary.plannedExpensesBalance} zł.`),
            prepareTableRow(t('Budget balance'), `${summary.budgetBalance} zł.`),
        ];
        setTableData(rows);
    }

    const prepareTableRow = (name: string, value: string) => {
        return {name, value} as RowItem;
    }

    useEffect(() => {
        if (!summary) {
            return;
        }
        calculateBudgetProgress(summary.incomeAmount, summary.realExpensesAmount);
        calculateExpensesProgress(summary)
        prepareTableData();
    }, [summary]);

    if (error) {
        return <Alert severity="error">{t("Cannot load budget summary.")}</Alert>;
    }

    if (isLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    return (
        <div>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <h4>{t('Expenses progress')}</h4>
                <LinearProgressWithLabel value={budgetProgress}/>
                <h4>{t('Unplanned vs. Planned')}</h4>
                <LinearProgressWithLabel value={expensesProgress}/>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 200}} aria-label="customized table">
                        <TableBody>
                            {tableData.map((row: RowItem) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">{row.name}</StyledTableCell>
                                    <StyledTableCell align="right">{row.value}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};