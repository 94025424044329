import * as React from 'react';
import {useState} from 'react';
import BudgetForm from "../../components/budget-form/BudgetForm";
import BudgetList from "../../components/budget-list/BudgetList";
import Box from "@mui/material/Box";
import {Alert, Breadcrumbs, CircularProgress, Tab, Tabs} from "@mui/material";
import {a11yProps} from "../../utils/tab.utils";
import TabPanel from "../../components/tab-panel/TabPanel";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import {useFetchBudgetsQuery} from "../../store";
import {getCookieTime} from "../../utils/cookie.utils";
import {cookies} from "../../utils/cookies";
import {LoadingItem} from "../../components/loading-item/LoadingItem";

function Budget() {
    const {t} = useTranslation();
    const [tabValue, setTabValue] = useState(1);
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const {data: pageable, error, isLoading} = useFetchBudgetsQuery({page, pageSize});

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleOnSubmit = () => {
        setTabValue(1);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangePageSize = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('Budgets')}</Typography>
            </Breadcrumbs>
            <h1>{t('Budgets')}</h1>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="tabs">
                    <Tab label={t('Create new')} {...a11yProps(0)} />
                    <Tab label={t('List')} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <h2>{t('Create new budget')}</h2>
                <BudgetForm onSubmit={handleOnSubmit}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <h2>{t('Budget list')}</h2>
                {isLoading && (<LoadingItem><CircularProgress/></LoadingItem>)}
                {error && (<Alert severity="error">{t("Cannot create budget!")}</Alert>)}
                {(!isLoading && !error && pageable) && (<BudgetList
                    pageable={pageable}
                    page={page}
                    pageSize={pageSize}
                    onChangePage={handleChangePage}
                    onChangePageSize={handleChangePageSize}
                />)}
            </TabPanel>
        </div>
    );
}

export default Budget;