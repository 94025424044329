import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";
import ExpenseDescriptionDto from "../../dto/ExpenseDescriptionDto";
import ExpenseDescriptionsRequest from "../../interfaces/ExpenseDescriptionsRequest";


const expenseDescriptionApi = createApi({
    reducerPath: "expenseDescription",
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/expense-description`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchExpenseDescriptions: builder.query<ExpenseDescriptionDto[], ExpenseDescriptionsRequest>({
            query: (payload) => {
                return {
                    url: `/${payload.budgetBusinessKey}?q=${payload.q}`,
                    method: 'GET'
                }
            },
        })
    })
});

export const {
    useFetchExpenseDescriptionsQuery,
} = expenseDescriptionApi;
export {expenseDescriptionApi};