import {createSlice} from "@reduxjs/toolkit";
import {prepareDateFrom, prepareDateTo} from "../../utils/filter.utils";

const initialState = {
    dateFrom: prepareDateFrom(),
    dateTo: prepareDateTo(),
};
const filterSlice = createSlice({
    name: "filter",
    initialState: initialState,
    reducers: {
        updateFilter: (state, action) => {
            state.dateFrom = action.payload.dateFrom;
            state.dateTo = action.payload.dateTo;
        }
    }
})

export const {updateFilter} = filterSlice.actions;
export const filterReducer = filterSlice.reducer;