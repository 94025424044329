import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";
import UpdatePasswordRequestDto from "../../dto/UpdatePasswordRequestDto";
import UserMessagesRequest from "../../interfaces/UserMessagesRequest";
import PageableDto from "../../dto/PageableDto";
import UserMessageDto from "../../dto/UserMessageDto";


const userApi = createApi({
    reducerPath: "user",
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/user`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchUserWhoami: builder.query({
            query: () => {
                return {
                    url: '/whoami',
                    method: 'GET'
                }
            }
        }),
        fetchUserProgress: builder.query({
            query: () => {
                return {
                    url: '/progress',
                    method: 'GET'
                }
            },
        }),
        fetchUserMessages: builder.query<PageableDto<UserMessageDto[]>, UserMessagesRequest>({
            query: (request: UserMessagesRequest) => {
                const {page, pageSize} = request;
                return {
                    url: `/message?page=${page}&size=${pageSize}`,
                    method: 'GET'
                }
            },
        }),
        fetchUserMessagesCountUnread: builder.query<PageableDto<number>, void>({
            query: () => {
                return {
                    url: '/message/count-unread',
                    method: 'GET'
                }
            },
        }),
        updatePassword: builder.mutation<void, UpdatePasswordRequestDto>({
            query: (request: UpdatePasswordRequestDto) => {
                return {
                    url: '/updatePassword',
                    method: 'PATCH',
                    body: request
                }
            },
        }),
        markMessageAsRead: builder.mutation<void, string>({
            query: (id: string) => {
                return {
                    url: `/message/mark-as-read/${id}`,
                    method: 'PUT'
                }
            },
        })
    })
});

export const {
    useFetchUserWhoamiQuery,
    useFetchUserProgressQuery,
    useLazyFetchUserProgressQuery,
    useUpdatePasswordMutation,
    useMarkMessageAsReadMutation,
    useFetchUserMessagesQuery,
    useFetchUserMessagesCountUnreadQuery
} = userApi;
export {userApi};