import * as React from 'react';
import {useEffect} from 'react';
import {Backdrop, CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

export default function AppLoader() {
    const isLoading = useSelector((state: RootState) => state.loader.isLoading);

    useEffect(() => {

    }, [isLoading]);


    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={isLoading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
};
