import {endOfMonth, setHours, setMinutes, setSeconds, startOfMonth} from "date-fns";
import {formatDateTime} from "./date.utils";


const prepareDateFrom = () => {
    const currentDate = new Date();
    const firstDayOfMonth = startOfMonth(currentDate);
    const dateTime = setSeconds(setMinutes(setHours(firstDayOfMonth, 0), 0), 0);
    return formatDateTime(dateTime);
};

const prepareDateTo = () => {
    const currentDate = new Date();
    const firstDayOfMonth = endOfMonth(currentDate);
    const dateTime = setSeconds(setMinutes(setHours(firstDayOfMonth, 23), 59), 59);
    return formatDateTime(dateTime);
};

export {prepareDateFrom, prepareDateTo};