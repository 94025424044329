import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";


const expenseCategoryApi = createApi({
    reducerPath: "expenseCategory",
    tagTypes: ['ExpenseCategories'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/expense-category`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createExpenseCategory: builder.mutation({
            query: (body) => {
                return {
                    url: '',
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: ['ExpenseCategories'],
        }),
        fetchExpenseCategories: builder.query({
            query: (budgetBusinessKey) => {
                return {
                    url: `/${budgetBusinessKey}`,
                    method: 'GET'
                }
            },
            providesTags: ['ExpenseCategories'],
        })
    })
});

export const {
    useFetchExpenseCategoriesQuery,
    useLazyFetchExpenseCategoriesQuery,
    useCreateExpenseCategoryMutation
} = expenseCategoryApi;
export {expenseCategoryApi};