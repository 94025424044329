import LoginRequestDto from "../dto/LoginRequestDto";
import TokenDto from "../dto/TokenDto";
import RefreshTokenRequestDto from "../dto/RefreshTokenRequestDto";
import baseUrl from "./baseUrl";

export const postLogin = async (loginRequest: LoginRequestDto): Promise<TokenDto> => {
    const response = await fetch(`${baseUrl}/api/user/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginRequest),
    });

    if (!response.ok) {
        console.error('Login request failed!');
        return {} as TokenDto;
    }

    const data = await response.json();
    return data as TokenDto;
};

export const postRefreshToken = async (token: string): Promise<TokenDto> => {
    try {
        const refreshTokenRequest = {token} as RefreshTokenRequestDto;

        const response = await fetch(`${baseUrl}/api/refresh-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(refreshTokenRequest),
        });

        if (!response.ok) {
            console.error('Refresh token request failed!');
            return {} as TokenDto;
        }

        const data = await response.json();
        return data as TokenDto;
    } catch (error) {
        console.error('Refresh token request failed:', error);
        return {} as TokenDto;
    }
};
