import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PageableDto from "../../dto/PageableDto";
import RevenueDto from "../../dto/RevenueDto";
import {TableHead} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RevenueEditDialog from "../revenue-edit-dialog/RevenueEditDialog";
import {rowsPerPageOptions} from "../../constants/pagination";
import RevenueRepeatDialog from "../revenue-repeat-dialog/RevenueRepeatDialog";
import {formatDateString} from "../../utils/date.utils";
import {useTranslation} from "react-i18next";
import {useDeleteRevenueMutation} from "../../store";

interface RevenueListProps {
    pageable: PageableDto<RevenueDto[]>;
    page: number;
    pageSize: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onChangePageSize: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    afterChange: () => void;
}

export default function RevenueList(props: RevenueListProps) {
    const {t} = useTranslation();
    const {
        pageable,
        page,
        pageSize,
        onChangePage,
        onChangePageSize,
        afterChange
    } = props;
    const [deleteRevenue, {isLoading, error}] = useDeleteRevenueMutation(); //todo: handle isLoading and error


    const getItemByBusinessKey = (businessKey: string) => {
        return pageable.content.find((item) => item.businessKey === businessKey);
    };

    const getMessage = (item: any) => {
        return `${t('Are you sure delete revenue')}:\n${t('Description')}: ${item?.name}\n
        ${t('Amount')}: ${item?.amount} zł.\n${t('Receipt at')}: ${item?.receiptAt}\n?`
    };

    const deleteByBusinessKey = async (businessKey: string) => {
        const item = getItemByBusinessKey(businessKey);
        if (confirm(getMessage(item))) {
            await deleteRevenue(businessKey);
            afterChange();
        }
    }

    return (
        <div>
            {pageable?.content
                ? <TableContainer component={Paper}>
                    <Table sx={{minWidth: 300}} aria-label={t('Revenues list')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Name')}</TableCell>
                                <TableCell>{t('Amount')}</TableCell>
                                <TableCell>{t('Receipt at')}</TableCell>
                                <TableCell>{t('Actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageable.content.map((row) => (
                                <TableRow key={`${row.name}-${row.createdAt}`}>
                                    <TableCell style={{width: 100}} align="left">
                                        {row.name}
                                    </TableCell>
                                    <TableCell style={{width: 50}} align="left">
                                        {row.amount} zł
                                    </TableCell>
                                    <TableCell style={{width: 50}} align="left">
                                        {formatDateString(row.receiptAt)}
                                    </TableCell>
                                    <TableCell style={{width: 50}} align="left">
                                        <RevenueRepeatDialog revenue={row}/>
                                        <RevenueEditDialog revenue={row}/>
                                        <IconButton onClick={() => deleteByBusinessKey(row.businessKey)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    colSpan={3}
                                    count={pageable.totalElements}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': `rows per page (Total: ${pageable.totalElements}`,
                                        },
                                        native: true,
                                    }}
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangePageSize}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer> : <p>{t('No data')}</p>}
        </div>
    );
}