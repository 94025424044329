import * as React from 'react';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RealExpenseDto from "../../dto/RealExpenseDto";
import {useTranslation} from "react-i18next";
import {useDeleteRealExpenseMutation} from "../../store";

interface Props {
    row: RealExpenseDto
    onDeleted: any
}

export default function RealExpenseDelete(props: Props) {
    const {t} = useTranslation();
    const {row, onDeleted} = props;
    const [deleteRealExpense, {isLoading, error}] = useDeleteRealExpenseMutation();

    const getMessage = () => {
        return `${t('Are you sure delete real expense')}:\n${t('Description')}: ${row?.description}\n
        ${t('Amount')}: ${row?.amount} zł.\n${t('Paid at')}: ${row?.paidAt}\n?`
    };

    const deleteById = async (businessKey: string) => {
        if (confirm(getMessage())
        ) {
            await deleteRealExpense(businessKey);
            onDeleted();
        }
    }

    return (
        <IconButton onClick={() => deleteById(row.businessKey)}>
            <DeleteIcon/>
        </IconButton>
    );
};