import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";

const budgetsDropdownApi = createApi({
    reducerPath: "budgetsDropdown",
    tagTypes: ['BudgetsDropdown'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/budget`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchBudgetsDropdown: builder.query({
            query: () => {
                return {
                    url: '/my/dropdown',
                    method: 'GET'
                }
            },
            providesTags: ['BudgetsDropdown'],
        })
    })
});

export const {useFetchBudgetsDropdownQuery, useLazyFetchBudgetsDropdownQuery} = budgetsDropdownApi;
export {budgetsDropdownApi};