import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PageableDto from "../../dto/PageableDto";
import {TableHead} from "@mui/material";
import Box from "@mui/material/Box";
import {rowsPerPageOptions} from "../../constants/pagination";
import {getCookieTime} from "../../utils/cookie.utils";
import {useTranslation} from "react-i18next";
import PlanningDto from "../../dto/PlanningDto";
import PlanningListRow from "../planning-list-row/PlanningListRow";
import {format} from "date-fns";
import {cookies} from "../../utils/cookies";

interface Props {
    pageable: PageableDto<PlanningDto[]>;
    page: number;
    pageSize: number;
    setPage: any;
    setPageSize: any;
}

export default function PlanningList(props: Props) {
    const {t} = useTranslation();
    const {
        pageable,
        page,
        pageSize,
        setPage,
        setPageSize,
    } = props;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        // loadData(newPage, pageSize); todo: check it works without it
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        // loadData(0, newPageSize, ''); todo: check it works without it
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    };

    const isSelectedRow = (yearMonth: string) => {
        const nowYearMonth = format(new Date(), 'yyyy-MM');
        return nowYearMonth === yearMonth;
    };

    return (
        <div>
            {pageable.content.length > 0
                ?
                <div>
                    <Box sx={{paddingTop: '1%', paddingBottom: '1%'}}>

                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 300}} aria-label={`${t('Monthly planning')}`}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Month')}</TableCell>
                                    <TableCell>{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageable.content.map((row) => (
                                    <PlanningListRow
                                        key={`${row.budgetBusinessKey}-${row.yearMonth}`}
                                        row={row}
                                        isSelected={isSelectedRow(row.yearMonth)}
                                    />
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        colSpan={3}
                                        count={pageable.totalElements}
                                        rowsPerPage={pageSize}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': `${t('rows per page')}`,
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div> : null}
        </div>
    );
}