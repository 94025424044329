import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import PlanningDto from "../../dto/PlanningDto";
import {Link, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {Alert, CircularProgress, Step, StepButton, StepContent, Stepper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PlanningUpdateRequestDto from "../../dto/PlanningUpdateRequestDto";
import RevenuePlanning from "../revenue-planning/RevenuePlanning";
import {endOfMonth, parse, setHours, setMinutes, setSeconds, startOfMonth} from "date-fns";
import PlannedExpensePlanning from "../planned-expense-planning/PlannedExpensePlanning";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import {LoadingItem} from "../loading-item/LoadingItem";
import {formatDateTime} from "../../utils/date.utils";
import PlannedExpenseSuggestions from "../planned-expense-suggestions/PlannedExpenseSuggestions";
import {useFetchPlannedExpenseSummaryQuery, useFetchRevenueSummaryQuery, useUpdatePlanningMutation} from "../../store";

interface Props {
    planning: PlanningDto,
    expenseCategories: ExpenseCategoryDto[]
}

export default function PlanningMonthStepper(props: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {
        planning,
        expenseCategories
    } = props;
    const defaultStep = 2;
    const [activeStep, setActiveStep] = useState(defaultStep);
    const initialCompleted = {
        0: false,
        1: false
    };
    const [completed, setCompleted] = useState<{
        [k: number]: boolean;
    }>(initialCompleted);
    const [updatePlanning, {
        error: updatePlanningError,
        isLoading: isUpdatePlanningLoading
    }] = useUpdatePlanningMutation();

    const parseYearMonth = (yearMonth: string) => {
        return parse(yearMonth, 'yyyy-MM', new Date());
    };

    const prepareDateFrom = (yearMonth: string) => {
        const currentDate = parseYearMonth(yearMonth);
        const firstDayOfMonth = startOfMonth(currentDate);
        const dateTime = setSeconds(setMinutes(setHours(firstDayOfMonth, 0), 0), 0);
        return formatDateTime(dateTime);
    };

    const prepareDateTo = (yearMonth: string) => {
        const currentDate = parseYearMonth(yearMonth);
        const firstDayOfMonth = endOfMonth(currentDate);
        const dateTime = setSeconds(setMinutes(setHours(firstDayOfMonth, 23), 59), 59);
        return formatDateTime(dateTime);
    };

    const dateFrom = prepareDateFrom(planning.yearMonth);
    const dateTo = prepareDateTo(planning.yearMonth);

    const {
        data: plannedSummary,
        isLoading: isPlannedSummaryLoading,
        error: plannedSummaryError
    } = useFetchPlannedExpenseSummaryQuery(
        {
            budgetBusinessKey: planning.budgetBusinessKey,
            dateFrom,
            dateTo
        }
    );

    const {
        data: revenueSummary,
        isLoading: isRevenueSummaryLoading,
        error: revenueSummaryError
    } = useFetchRevenueSummaryQuery({
        budgetBusinessKey: planning.budgetBusinessKey,
        dateFrom,
        dateTo
    });

    const handleNext = async () => {
        const data = {
            id: planning.id,
            state: planning.state,
            revenuesCompleted: activeStep === 0 ? true : null,
            plannedExpensesCompleted: activeStep === 1 ? true : null
        } as PlanningUpdateRequestDto;
        await updatePlanning(data);
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        if (step === activeStep) {
            setActiveStep(defaultStep);
            return;
        }
        setActiveStep(step);
    };

    const updateAndRedirect = async (id: string, state: string, revenuesCompleted: boolean, plannedExpensesCompleted: boolean) => {
        const data = {
            id, state, revenuesCompleted, plannedExpensesCompleted
        } as PlanningUpdateRequestDto;
        await updatePlanning(data);
        navigate("/planning")
    };

    const init = (planning: PlanningDto) => {
        const newCompleted = {
            0: planning.revenuesCompleted,
            1: planning.plannedExpensesCompleted
        };
        setCompleted(newCompleted);
    }

    const allStepsCompleted = () => {
        return completed[0] && completed[1];
    };

    const summariesValid = () => {
        if (!revenueSummary || !plannedSummary) {
            return false;
        }

        return parseFloat(revenueSummary.amount) >= parseFloat(plannedSummary.amount);
    };

    const planningValid = () => {
        return allStepsCompleted() && summariesValid();
    };

    const revenueSummaryValid = (): boolean => {
        if (!revenueSummary) {
            return false;
        }
        return revenueSummary.amount !== '0.00';
    };

    const isLoading = () => {
        return isPlannedSummaryLoading || isUpdatePlanningLoading || isRevenueSummaryLoading;
    };

    useEffect(() => {
        if (planning.yearMonth) {
            init(planning);
        }
    }, [planning]);

    if (isLoading()) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (plannedSummaryError) {
        return <Alert severity="error">{t("Cannot load planned expenses summary!")}</Alert>
    }

    if (revenueSummaryError) {
        return <Alert severity="error">{t("Cannot load revenues summary!")}</Alert>
    }

    if (updatePlanningError) {
        return <Alert severity="error">{t("Cannot update planning!")}</Alert>
    }

    return (
        <div>
            {!isLoading() ? <Box>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key="revenues" completed={completed[0]}>
                        <StepButton
                            color="inherit"
                            onClick={handleStep(0)}
                            optional={revenueSummary && (<Typography variant="caption">
                                {t('Total amount')}: {revenueSummary.amount} zł.</Typography>)}
                        >
                            {t('Revenues')}
                        </StepButton>
                        <StepContent>
                            <RevenuePlanning
                                budgetBusinessKey={planning.budgetBusinessKey}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                afterSave={() => {
                                }} //todo: check is refetch necessary here
                                onClickContinue={handleNext}
                                revenueSummaryValid={revenueSummaryValid()}
                            />
                        </StepContent>
                    </Step>
                    <Step key="planned-expenses" completed={completed[1]}>
                        <StepButton
                            color="inherit"
                            onClick={handleStep(1)}
                            optional={<Typography variant="caption">
                                {t('Total amount')}: {plannedSummary.amount} zł.</Typography>}
                        >
                            {t('Planned expenses')}
                        </StepButton>
                        <StepContent>
                            <PlannedExpenseSuggestions budgetBusinessKey={planning.budgetBusinessKey}
                                                       yearMonth={planning.yearMonth}/>
                            <PlannedExpensePlanning
                                expenseCategories={expenseCategories}
                                budgetBusinessKey={planning.budgetBusinessKey}
                                dateFrom={prepareDateFrom(planning.yearMonth)}
                                dateTo={prepareDateTo(planning.yearMonth)}
                                onClickBack={handleBack}
                                onClickContinue={handleNext}
                                summariesValid={() => summariesValid()}
                            />
                        </StepContent>
                    </Step>
                </Stepper>
                {activeStep === 2 && (
                    <Paper square elevation={0} sx={{p: 3}}>

                        <div>
                            {!summariesValid() ? <Alert severity="error">
                                {t('The total planned expenses exceed the sum of revenues!')}
                            </Alert> : null}
                        </div>
                        <div>
                            <Typography></Typography>
                        </div>
                        <div>
                            <Button
                                sx={{mt: 1, mr: 1}}
                                component={Link}
                                to={"/planning"}
                            >
                                {t('Back')}
                            </Button>
                            {planning.state !== "PLANNED" ? <Button
                                onClick={() => updateAndRedirect(planning.id, "DRAW", planning.revenuesCompleted, planning.plannedExpensesCompleted)}
                                sx={{mt: 1, mr: 1}}
                            >
                                {t('Save draw')}
                            </Button> : null}
                            {planning.state !== "PLANNED" ? <Button
                                disabled={!planningValid()}
                                variant="contained"
                                onClick={() => updateAndRedirect(planning.id, "PLANNED", planning.revenuesCompleted, planning.plannedExpensesCompleted)}
                                sx={{mt: 1, mr: 1}}
                            >
                                {t('Mark as planned')}
                            </Button> : null}
                        </div>
                    </Paper>
                )}
            </Box> : <LoadingItem><CircularProgress/></LoadingItem>}
        </div>
    );
}