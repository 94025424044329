import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";
import RevenueSearchRequest from "../../interfaces/RevenueSearchRequest";
import RevenueUpdateRequestDto from "../../dto/RevenueUpdateRequestDto";
import RevenueRequestDto from "../../dto/RevenueRequestDto";
import RevenueSummarySearchRequest from "../../interfaces/RevenueSummarySearchRequest";
import RevenueSummaryDto from "../../dto/RevenueSummaryDto";

const revenueApi = createApi({
    reducerPath: "revenue",
    tagTypes: ['Revenues'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/revenue`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createRevenue: builder.mutation<void, RevenueRequestDto>({
            query: (body: RevenueRequestDto) => {
                return {
                    url: '',
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: ['Revenues'],
        }),
        deleteRevenue: builder.mutation({
            query: (businessKey: string) => {
                return {
                    url: `/${businessKey}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: ['Revenues'],
        }),
        updateRevenue: builder.mutation<void, RevenueUpdateRequestDto>({
            query: (request: RevenueUpdateRequestDto) => {
                return {
                    url: `/${request.businessKey}`,
                    method: 'PUT',
                    body: request
                }
            },
            invalidatesTags: ['Revenues'],
        }),
        fetchRevenues: builder.query({
            query: (request: RevenueSearchRequest) => {
                const {budgetBusinessKey, page, pageSize, dateFrom, dateTo} = request;
                return {
                    url: `/${budgetBusinessKey}?page=${page}&size=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
                    method: 'GET'
                }
            },
            providesTags: ['Revenues'],
        }),
        fetchRevenueSummary: builder.query<RevenueSummaryDto, RevenueSummarySearchRequest>({
            query: (request: RevenueSummarySearchRequest) => {
                const {budgetBusinessKey, dateFrom, dateTo} = request;
                return {
                    url: `/summary/${budgetBusinessKey}?&dateFrom=${dateFrom}&dateTo=${dateTo}`,
                    method: 'GET'
                }
            },
            providesTags: ['Revenues'],
        })
    })
});

export const {
    useCreateRevenueMutation,
    useFetchRevenuesQuery,
    useDeleteRevenueMutation,
    useUpdateRevenueMutation,
    useFetchRevenueSummaryQuery,
} = revenueApi;
export {revenueApi};