import {Outlet} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Filters from "./components/filters/Filters";
import React from "react";
import Redirector from "./components/redirector/Redirector";
import AppDataLoader from "./components/app-data-loader/AppDataLoader";

function Layout() {
    return (
        <>
            <Redirector>
                <AppDataLoader>
                    <Navbar/>
                    <Filters/>
                    <Outlet/>
                    {/*todo: create footer here*/}
                </AppDataLoader>
            </Redirector>
        </>
    )
}

export default Layout;