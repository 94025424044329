import * as React from 'react';
import {useState} from 'react';
import {Alert, Checkbox, CircularProgress, Stack, TextField} from "@mui/material";
import LoginRequestDto from "../../dto/LoginRequestDto";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import FormItem from "../form-item/FormItem";
import {LoadingItem} from "../loading-item/LoadingItem";
import {postLogin} from "../../api/api";
import {useDispatch} from "react-redux";
import TokenDto from "../../dto/TokenDto";
import {setAuthData, useFetchIsOnlineQuery} from "../../store";
import {useNavigate} from "react-router-dom";


const LoginForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {t, i18n} = useTranslation();
    const {data: isOnline} = useFetchIsOnlineQuery();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const loginData = data as LoginRequestDto;
        postLogin(loginData).then((tokenDto: TokenDto) => {
            dispatch(setAuthData(tokenDto));
            navigate("/");
        }).catch((err) => {
            setError(err);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Stack spacing={2}>
                    <FormItem>
                        <h3>{t('Login')}:</h3>
                    </FormItem>
                    {isLoading && (<LoadingItem><CircularProgress/></LoadingItem>)}
                    {error && (<Alert severity="error">{t("Login error! Message: {error}")}</Alert>)}
                    <FormItem>
                        <TextField
                            fullWidth
                            defaultValue="" {...register("username", {required: true})}
                            label={t('Username')}
                            helperText={errors.username && t('Field is required')}
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            id="outlined-password-input"
                            label={t('Password')}
                            type="password"
                            autoComplete="current-password"
                            {...register("password", {required: true})}
                            helperText={errors.password && t('Field is required')}
                        />
                    </FormItem>
                    <FormItem>
                        <Checkbox
                            inputProps={{'aria-label': 'Remember?'}}
                            {...register("remember", {required: true})}
                            defaultChecked
                        />
                    </FormItem>
                    <FormItem>
                        <Button variant="contained" type="submit" disabled={!isOnline}>{t('Login')}</Button>
                    </FormItem>
                </Stack>
            </Box>
        </form>
    )
}

export default LoginForm
