import * as React from 'react';
import IconButton from "@mui/material/IconButton";
import PlannedExpenseDto from "../../dto/PlannedExpenseDto";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";
import {useDeletePlannedExpenseMutation} from "../../store";
import {LoadingItem} from "../loading-item/LoadingItem";
import {Alert, CircularProgress} from "@mui/material";

interface Props {
    row: PlannedExpenseDto
}

export default function PlannedExpenseDelete(props: Props) {
    const {t} = useTranslation();
    const {row} = props;
    const [deletePlannedExpense, {isLoading, error}] = useDeletePlannedExpenseMutation();

    const getMessage = () => {
        return `${t('Are you sure delete planned expense')}:\n${t('Description')}: ${row?.description}\n
        ${t('Amount')}: ${row?.amount} zł.\n${t('To pay at')}: ${row?.toPayAt}\n?`;
    }

    const deleteById = async (businessKey: string) => {
        if (confirm(getMessage())) {
            await deletePlannedExpense(businessKey);
        }
    }

    if (isLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (error) {
        return <Alert severity="error">{t("Cannot delete planned expense!")}</Alert>;
    }

    return (
        <IconButton onClick={() => deleteById(row.businessKey)} disabled={isLoading}>
            <DeleteIcon/>
        </IconButton>
    );
};