import * as React from 'react';
import {useEffect, useState} from 'react';
import {Alert, Autocomplete, CircularProgress, FormControl, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState, useFetchExpenseDescriptionsQuery} from "../../store";
import {LoadingItem} from "../loading-item/LoadingItem";

interface Props {
    value: string;
    onSelect: any;
}

export default function ExpenseDescriptionAutocomplete(props: Props) {
    const {t} = useTranslation();
    const {value, onSelect} = props;
    const [q, setQ] = useState(value ?? '');
    const selectedBudget = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const {
        data: expenseDescriptions,
        error,
        isLoading
    } = useFetchExpenseDescriptionsQuery({
        budgetBusinessKey: selectedBudget,
        q: q
    }, {
        skip: q === ''
    });

    const handleInputChange = (val: string) => {
        onSelect(null, val);
        if (val.length >= 3) {
            setQ(val);
        }
    };

    const handleEventInputChange = (event: any) => {
        const inputValue = event.target.value;
        handleInputChange(inputValue);
    };

    const getOptions = () => {
        if (!expenseDescriptions) {
            return [];
        }
        return expenseDescriptions.map(item => item.name);
    }

    useEffect(() => {
        handleInputChange(value);
    }, []);

    if (isLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (error) {
        return <Alert severity="error">{t("Cannot load expense descriptions!")}</Alert>;
    }

    return (
        <div>
            <FormControl fullWidth>
                <Autocomplete
                    id="description-autocomplete"
                    options={getOptions()}
                    onChange={onSelect}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    freeSolo
                    openOnFocus
                    value={value}
                    ListboxProps={{style: {maxHeight: 200}}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={t('Description')}
                            onChange={handleEventInputChange}
                            autoFocus
                        />}
                />
            </FormControl>
        </div>
    );
};