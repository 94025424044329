import * as React from 'react';
import Box from "@mui/material/Box";
import PasswordUpdateForm from "../../components/password-update-form/PasswordUpdateForm";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {Alert, Breadcrumbs, CircularProgress} from "@mui/material";
import {useTranslation} from "react-i18next";
import {RootState, useFetchUserWhoamiQuery} from "../../store";
import {useSelector} from "react-redux";
import {LoadingItem} from "../../components/loading-item/LoadingItem";

function User() {
    const {t} = useTranslation();
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
    const {data: user, error, isLoading} = useFetchUserWhoamiQuery(undefined, {
        skip: !isLoggedIn,
    });

    if (isLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (error) {
        return <Alert severity="error">{t("Cannot load user data!")}</Alert>;
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('User profile')}</Typography>
            </Breadcrumbs>
            <h2>{t('Hello')} {user?.name}</h2>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                User messages here
            </Box>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <PasswordUpdateForm></PasswordUpdateForm>
            </Box>
        </div>
    );
};

export default User;