import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import {format, formatISO, parseISO} from "date-fns";
import RevenueDto from "../../dto/RevenueDto";
import RevenueRequestDto from "../../dto/RevenueRequestDto";
import {sleep} from "../../constants/sleeper";
import {defaultDateTimeFormat, prepareSequenceOfDates} from "../../utils/date.utils";
import {useTranslation} from "react-i18next";
import {LoadingItem} from "../loading-item/LoadingItem";
import ContentItem from "../content-item/ContentItem";
import {useCreateRevenueMutation} from "../../store";


interface Props {
    revenue: RevenueDto,
}

export default function RevenueRepeatDialog(props: Props) {
    const {t} = useTranslation();
    const {revenue} = props;
    const {register, handleSubmit, setValue, trigger, formState: {errors}} = useForm();
    const [open, setOpen] = useState(false);
    const [repeatFrequency, setRepeatFrequency] = useState("0");
    const [timesAmount, setTimesAmount] = useState(1);
    const [saveDates, setSaveDates] = useState<Date[]>([]);
    const [loading, setLoading] = useState(false);
    const [createRevenue, {isLoading, error}] = useCreateRevenueMutation(); // todo: handle isLoading and error

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onChangeRepeatFrequency = (event: any, value: string) => {
        setRepeatFrequency(value);
        setValue("repeatFrequency", value);
        prepareDates(parseISO(revenue.receiptAt), value, timesAmount);
    }

    const onChangeTimesAmount = (event: any) => {
        setTimesAmount(event.target.value);
        setValue("timesAmount", event.target.value);
        prepareDates(parseISO(revenue.receiptAt), repeatFrequency, event.target.value);
    }

    const prepareDates = (startDate: Date, frequency: string, count: number) => {
        const dates = prepareSequenceOfDates(startDate, frequency, count);
        setSaveDates(dates);
    };

    const prepareRequest = (receiptAt: string) => {
        const {businessKey, updatedAt, createdAt, ...rest} = revenue;
        return {...rest, receiptAt} as RevenueRequestDto;
    }

    const saveRevenues = async () => {
        setLoading(true);
        for (const saveDate of saveDates) {
            await sleep(50);
            const date = formatISO(saveDate);
            await createRevenue(prepareRequest(date));
        }
    };

    const onSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
            saveRevenues().then(() => {
                setOpen(false);
                setLoading(false);
            });
        }
    };

    const initForm = () => {
        prepareDates(parseISO(revenue.receiptAt), repeatFrequency, timesAmount);
    };

    useEffect(() => {
        initForm();
    }, []);

    return (<div>
            <IconButton onClick={handleClickOpen}>
                <EventRepeatIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('Repeat revenue')}</DialogTitle>
                {loading ? <DialogContent>
                        <Box sx={{width: '100%'}}>
                            <Stack spacing={2}>
                                <LoadingItem><CircularProgress/></LoadingItem>
                            </Stack>
                        </Box>
                    </DialogContent>
                    : <DialogContent>
                        <DialogContentText>
                            {t('Name')}: {revenue.name}<br/>
                            {t('Amount')}: {revenue.amount}<br/>
                            {t('Receipt at')}: {revenue.receiptAt}
                        </DialogContentText>
                        <form>
                            <Box sx={{width: '100%'}}>
                                <Stack spacing={2}>
                                    <ContentItem>
                                        <FormControl fullWidth>
                                            <FormLabel
                                                id="planned-expense-repeat-frequency-label">{t('Frequency')}</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="planned-expense-repeat-frequency-label"
                                                value={repeatFrequency}
                                                {...register("repeatFrequency", {required: true})}
                                                onChange={onChangeRepeatFrequency}
                                            >
                                                <FormControlLabel value="0" control={<Radio/>} label={t('Monthly')}/>
                                                <FormControlLabel value="1" control={<Radio/>} label={t('Weekly')}/>
                                            </RadioGroup>
                                        </FormControl>
                                    </ContentItem>
                                    <ContentItem>
                                        <TextField fullWidth
                                                   id="times-amount"
                                                   label={t('Times amount')}
                                                   variant="outlined"
                                                   type={"number"}
                                                   inputProps={{min: 0, max: 12}}
                                                   value={timesAmount}
                                                   {...register("timesAmount", {required: true})}
                                                   onChange={onChangeTimesAmount}
                                        />
                                        {errors.timesAmount &&
                                            <FormHelperText>{t('Times amount is required')}</FormHelperText>}
                                    </ContentItem>
                                    {saveDates.length > 0 ? <ContentItem>
                                            {t('Generated dates')}:
                                            <ul>
                                                {saveDates.map((saveDate) =>
                                                    <li key={saveDate.toISOString()}>{format(saveDate, defaultDateTimeFormat)}
                                                    </li>
                                                )}
                                            </ul>
                                        </ContentItem>
                                        : null}
                                </Stack>
                            </Box>
                        </form>
                    </DialogContent>}
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button type="submit" onClick={handleSubmit(onSubmit)}>{t('Save')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};