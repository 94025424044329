import * as React from 'react';
import {useEffect, useState} from 'react';
import {Alert, CircularProgress, Grid} from "@mui/material";
import RealExpenseList from "../../components/real-expense-list/RealExpenseList";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import BudgetSummary from "../../components/budget-summary/BudgetSummary";
import {useTranslation} from "react-i18next";
import RealExpensesByCategory from "../../components/real-expenses-by-category/RealExpensesByCategory";
import {LoadingItem} from "../../components/loading-item/LoadingItem";
import FormItem from "../../components/form-item/FormItem";
import {useSelector} from "react-redux";
import {
    RootState,
    useFetchExpenseCategoriesQuery,
    useFetchPlannedExpensesDropdownQuery,
    useFetchRealExpenseSummaryQuery
} from "../../store";


function Home() {
    const {t, i18n} = useTranslation();
    const {dateFrom, dateTo} = useSelector((state: RootState) => state.filter);
    const selectedBudget = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const [month, setMonth] = useState('');

    const {
        data: expenseCategories,
        isLoading: isExpenseCatagoriesLoading
    } = useFetchExpenseCategoriesQuery(selectedBudget, {
        skip: !selectedBudget
    });

    const {
        data: expensesSummary,
        isLoading: isExpenseSummaryLoading, // todo: implement loader and error view
        error: isFetchExpenseSummaryError,
    } = useFetchRealExpenseSummaryQuery({
            budgetBusinessKey: selectedBudget, dateFrom, dateTo
        },
        {
            skip: !selectedBudget
        }
    );

    const {
        data: plannedExpensesData,
        isLoading: isPlannedExpensesLoading, // todo: implement loader and error view
        error: isPlannedExpensesError
    } = useFetchPlannedExpensesDropdownQuery({
            budgetBusinessKey: selectedBudget, dateFrom, dateTo
        },
        {
            skip: !selectedBudget
        }
    );

    const plannedExpenses = plannedExpensesData ?? [];


    useEffect(() => {
        // refetchRealExpenses(); todo: check it necessary
        const date = new Date(dateFrom);
        setMonth(format(date, 'MMMM yyyy'));
    }, [selectedBudget, dateFrom, dateTo]);

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <FormItem>
                        <h2>{t('Real expenses')} ({month})</h2>
                        <Button
                            component={Link}
                            to="/create-expense"
                            variant="contained">
                            {t('Create new expense')}
                        </Button>
                    </FormItem>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <FormItem>
                        <h3>{t('Your budget')}</h3>
                        <BudgetSummary/>
                    </FormItem>
                </Grid>
                <Grid item xs={12} lg={3}>
                    {(isExpenseSummaryLoading || isExpenseCatagoriesLoading) && (
                        <LoadingItem><CircularProgress/></LoadingItem>)}
                    {(!isExpenseSummaryLoading && !isExpenseCatagoriesLoading && expensesSummary) && (<FormItem>
                        <RealExpensesByCategory
                            expenseCategories={expenseCategories}
                            expensesSummary={expensesSummary}
                        />
                    </FormItem>)}
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FormItem>
                        <h3>{t('Real expenses list')}</h3>
                        {selectedBudget ? <RealExpenseList
                            selectedBudget={selectedBudget}
                            expenseCategories={expenseCategories}
                            plannedExpenses={plannedExpenses}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                        /> : <Alert severity="info">Select budget!</Alert>}
                    </FormItem>
                </Grid>
            </Grid>
        </div>
    );
}

export default Home;