import * as React from 'react';
import {useState} from 'react';
import {Alert, Breadcrumbs, CircularProgress} from "@mui/material";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import PlanningList from "../../components/planning-list/PlanningList";
import PlanningAlerts from "../../components/planning-alerts/PlanningAlerts";
import {LoadingItem} from "../../components/loading-item/LoadingItem";
import {useSelector} from "react-redux";
import {RootState, useFetchPlanningsQuery} from "../../store";

function Planning() {
    const {t} = useTranslation();
    const budgetBusinessKey = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const {data: pageable, error, isLoading} = useFetchPlanningsQuery({
        budgetBusinessKey,
        page,
        pageSize
    });


    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('Monthly planning')}</Typography>
            </Breadcrumbs>
            <h1>{t('Monthly planning')}</h1>
            {error && (<Alert severity="error">{t("Cannot load plannings!")}</Alert>)}
            {isLoading && (<LoadingItem><CircularProgress/></LoadingItem>)}
            {(!isLoading && !error) && (<div>
                <PlanningAlerts
                    budget={budgetBusinessKey}
                    pageable={pageable}
                />

                <PlanningList
                    pageable={pageable}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                />
            </div>)}
        </div>
    );
}

export default Planning;